<template>
    <div class="expertGuidance wrap1148 teachingResearch">
        <div class="tit-teachingResearch">
            <p>专家引领</p>
        </div>
        <ul class="list-cells2">
            <li v-for="(item,index) in contentList">
                <!-- <i class="s-tips s-tips-blue">小学英语</i> -->
                <div class="stu-imgBox">
                    <a href="javascript:;" @click="linkToDetails(item)">
                        <img :src="'https://oss.eyyb.vip/'+item.thumbImg" alt="" width="150" height="170">
                    </a>
                </div>
                <div class="txt-infors">
                    <h5><a href="javascript:;" @click="linkToDetails(item)">{{item.name}}</a></h5>
                    <p class="p-txt"> {{item.remarks}} </p>
                    <!-- <p class="s-gray clearfix"><span class="ellipsis">视频  10</span><span class="ellipsis">文章  10</span></p> -->
                </div>
            </li>
            <!-- <li>
                <i class="s-tips s-tips-blue">小学英语</i>
                <div class="stu-imgBox">
                    <a href="#"><img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="" width="150" height="170"></a>
                </div>
                <div class="txt-infors">
                    <h5><a href="#">李静纯</a></h5>
                    <p class="p-txt"> 男，研究员，1945年生于北京。毕业于天
                        津南开大学外语系 </p>
                    <p class="s-gray clearfix"><span class="ellipsis">视频  10</span><span class="ellipsis">文章  10</span></p>
                </div>
            </li>
            <li>
                <i class="s-tips s-tips-blue">小学英语</i>
                <div class="stu-imgBox">
                    <a href="#"><img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="" width="150" height="170"></a>
                </div>
                <div class="txt-infors">
                    <h5><a href="#">李静纯</a></h5>
                    <p class="p-txt"> 男，研究员，1945年生于北京。毕业于天
                        津南开大学外语系 </p>
                    <p class="s-gray clearfix"><span class="ellipsis">视频  10</span><span class="ellipsis">文章  10</span></p>
                </div>
            </li>
            <li>
                <i class="s-tips s-tips-blue">小学英语</i>
                <div class="stu-imgBox">
                    <a href="#"><img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="" width="150" height="170"></a>
                </div>
                <div class="txt-infors">
                    <h5><a href="#">李静纯</a></h5>
                    <p class="p-txt"> 男，研究员，1945年生于北京。毕业于天
                        津南开大学外语系 </p>
                    <p class="s-gray clearfix"><span class="ellipsis">视频  10</span><span class="ellipsis">文章  10</span></p>
                </div>
            </li> -->
        </ul>
    </div>
</template>

<style lang="scss" scope>
    @import '@/assets/css/pages/teachingResearch.scss';
</style>

<script>
    import {mapGetters} from "vuex";
    export default {
        data () {
            return {
                modulesId: '', // 大主题id
                moduleIdList: [], // 系列列表
                moduleId: '', // 系列列表当前选中
                contentList: [], // 系列下列表
            }
        },
        created () {
            this.modulesId = this.$route.query.moduleId;
            this.loadModels();
        },
        methods: {
            loadModels() { // 加载主题
                this.moduleIdListPick();
            },
            handleCurrentChange(val) { // 页码选择
                this.pageInfo.pageNum = val;
                this.loadPickList(this.pageInfo.pageNum);
            },
            moduleIdListPick() { // 模块选择
                this.loadPickList();
            },
            loadPickList() { // 加载列表内容
                this.$http({
                    method: 'get',
                    url: '/api/yyb/v1/expModule/web/queryExpertModule',
                    params: {
                        appId: 'TruXBvv2TTO9IJjPDrmRIw',
                        appMenu: 'vOt-hyZFSVidUKPuxK0Msw ',
                        timeStame: (new Date()).getTime()
                    }
                }).then(res => {
                    console.log('res', parseInt(res.data.obj));
                    this.contentList = res.data.obj;
                }).catch(error => {
                    console.log('error', error)
                })
            },
            linkToDetails(item) {
                this.$router.push({ 
                    name: 'subExpertGuidance',
                    params: {
                        id: item.id
                    },
                    query:{
                        name: `${item.name}`, 
                        themeId: this.moduleId
                    }
                });
            }
        }
    }
</script>